import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back to `}<a parentName="p" {...{
        "href": "/Case-studies",
        "title": "Case-studies"
      }}>{`Case-studies`}</a>{``}</p>
    <h3>{`Philippines builds on Taiwan model`}</h3>
    <p><strong parentName="p">{`Headline:`}</strong>{` Makati Business Club works with mayors on municipal policy consultations and trains government staff to run Polis on their own.
`}<strong parentName="p">{`When:`}</strong>{` 2020 - ongoing
`}<strong parentName="p">{`Where:`}</strong>{` Philippines
`}<strong parentName="p">{`Size:`}</strong>{` 3000+`}</p>
    <p><strong parentName="p">{`Details:`}</strong>{` In 2020, inspired by Taiwan’s successful pandemic response, Makati Business Club interviewed Audrey Tang for a “Freedom and Democracy Zine” and thereafter began piloting use of Polis with local governmental units. With buy-in from mayors across the Philippines thanks to the organization’s standing as business leaders, Makati Business Club has led 5 consultations in 6 cities reaching over 3000 people.`}</p>
    <p>{`The work of Makati Business Club closely follows the Taiwan model in three major ways: 1) "sandwich" model of two synchronous assemblies with an asynchronous Polis conversation in the middle, 2) a training and certification program for staff at government agencies to build institutional knowledge, 3) radical transparency in the publishing of all results even intermediate data steps as well as longform recordings of meetings. Uniquely, as this program is delivered by recognized multi-generational business leaders in the country, existing connections with the executive political class of mayors and boards of directors of large civil society groups nationwide sped its growth and reach. First offered in 2022, their workshop series, "Digital Democracy: Technology for Community-Building and Decision-Making," reached 16 local governmental units (LGU), 2 national governmental agencies, and 19 civil society organizations (CSO).`}</p>
    <h4>{`Links:`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Platform home:`}</strong>{` `}<a parentName="li" {...{
          "href": "https://mbc.com.ph/digital-democracy/"
        }}>{`https://mbc.com.ph/digital-democracy/`}</a></li>
      <li parentName="ul"><strong parentName="li">{`2022 Training in digital democracy:`}</strong>{` `}<a parentName="li" {...{
          "href": "https://mbc.com.ph/2022/05/25/mbc-wraps-up-digital-democracy-training-for-lgus-and-csos/"
        }}>{`https://mbc.com.ph/2022/05/25/mbc-wraps-up-digital-democracy-training-for-lgus-and-csos/`}</a></li>
      <li parentName="ul">{`Direct link to videos: `}<a parentName="li" {...{
          "href": "https://www.youtube.com/@makatibusinessclubforum/playlists?view=50&sort=dd&shelf_id=2"
        }}>{`https://www.youtube.com/@makatibusinessclubforum/playlists?view=50&sort=dd&shelf_id=2`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      